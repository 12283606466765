import './Header2.css';


function Header2(props) {
    let servMenu = props.navServices;
    const listServMenu = servMenu.map(item => <li key={item.link}><a href={item.link}>{item.text}</a></li>);

    return (
        <div className="header2">
            <nav className="header2-nav">
                {listServMenu}
            </nav>
        </div>
    );
}

export default Header2;
