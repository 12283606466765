import './Footer.css';

function Footer() {


    return (
        <div className="footer">
            <div className="footer-container">
                <div className="logo-footer"></div>
                <p>&#169; 2021 Bilta. <br />Всі права захищені.</p>
            </div>
        </div>
    );
}

export default Footer;
