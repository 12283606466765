import './Header.css';

function Header(props) {
    let navMenu = props.nav;
    const listNav = navMenu.map(item => <li key={item.link}><a href={item.link}>{item.text}</a></li>);

    return (
        <div className="header">
            <div className="logo-header"></div>
            <div className="header-block-right">
                <nav className="header-nav">
                    {listNav}
                </nav>
                <div className="mob-menu">
                    <div className="header-form" >
                        <a href="mailto:info@bilta.com">&#9993; Написати</a>
                    </div>
                    <div className="header-phone">
                        <a href="tel:+380952833557">&#9990; +38 044 536 99 11</a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Header;
