import './Main.css';
import metal from './Img/metal.jpg';
import beton from './Img/res.jpg';
import zbv from './Img/kar.jpg';
import rehau from './Img/rehau.jpg';
import imgmk1 from './Img/mk1.jpg';
import imgmk2 from './Img/mk2.jpg';
import reservoir from './Img/reservoir.jpg';
import karkas from './Img/karkas.jpg';
import imgreh from './Img/reh.jpg';

function Main() {
    return (
        <div>
            <div className="main-div">
                <div className="main-img">
                    <h1>Виробництво металоконструкцій, резервуарів, арматурних каркасів паль та світлопрозорих конструкцій</h1>
                </div>
                <div id="services"></div>
                <div className="main-services">
                    <h2 >Наші послуги</h2>
                    <div className="div-line"></div>
                </div>
                <div className="content-block">

                    <div id="metal" className="main-content">
                        <h2>Bиробництво металоконструкцій</h2>
                        <div><img src={metal} alt="" /></div>
                        <p>Ми забезпечуємо проектування і виготовлення металоконструкцій будь-якої складності на сучасному обладнанні в установлені строки з відмінним співвідношенням ціна/якість. Досвідчені фахівці  контролюють всі етапи виробництва та монтажу металоконструкцій: починаючи з креслень КМД, антикорозійної обробки конструкцій на сучасній установці з дробометного очищення металу, до сервісу з монтажу металоконструкцій, що дозволяє оптимізувати витрати замовника.</p>
                        <a className="button-details" href="#mk">Детальніше</a>
                    </div>

                    <div id="beton" className="main-content">
                        <h2>Виробництво резервуарів</h2>
                        <div><img src={beton} alt="" /></div>
                        <p>Виробляємо резервуари під виробничі та приватні потреби. Робимо попередній розрахунок вартості, проектуємо та працюємо з готовими проектами, використовуємо якісну сировину та слідкуємо за всіма етапами виробництва. Готові резервуари проходять антикорозійну обробку, готуються до транспортування та за потреби ми надаємо послуги доставки та монтажу.</p>
                        <a className="button-details" href="#tb">Детальніше</a>
                    </div>

                    <div id="zbv" className="main-content">
                        <h2>Каркаси для буронабивних паль</h2>
                        <div><img src={zbv} alt="" /></div>
                        <p>«Білта» виготовляє каркаси для буронабивних паль на сучасному обладнанні, з дотриманням високих стандартів якості і використанням високоякісного металу. Наші спеціалісти можуть працювати як з готовими кресленнями, так і проводити всі необхідні розрахунки на основі даних замовника. Налагоджений процес виробництва дозволяє виконувати замовлення вчасно, при цьому ми забезпечуємо оперативну доставку на об'єкти замовників.</p>
                        <a className="button-details" href="#bv">Детальніше</a>
                    </div>

                    <div id="rehau" className="main-content">
                        <h2>Світлопрозорі конструкції</h2>
                        <div><img src={rehau} alt="" /></div>
                        <p>«Bilta» є сертифікованим партнером компанії Rehau. Використання високоякісних матеріалів, наявність сучасного обладнання та контроль всіх етапів гарантують виробництво світлопрозорих конструкцій високої якості. Виробничі потужності дозволяють в найкоротші терміни виконувати замовлення, враховуючи побажання замовників, а також забезпечувати оперативну доставку виконаних замовлень на об'єкти клієнтів. Наші технічні можливості дозволяють виробляти світлопрозорі конструкції нестандартних розмірів.</p>
                        <a className="button-details" href="#reh">Детальніше</a>
                    </div>
                </div>
                <div className="advantages">
                    <h2 >Наші переваги:</h2>
                    <div className="advantages-box">
                        <div className="advantages-col">
                            <h3>&#10004;&#160;Висока якість робіт</h3>
                            <p>Професійне обладнання, використання сертифікованих матеріалів, кваліфікація працівників гарантують високу якість виробів та робіт.</p>
                        </div>
                        <div className="advantages-col">
                            <h3>&#10004;&#160;Оперативність</h3>
                            <p>Матеріально-технічна база та налагоджені процеси на всіх етапах дозволяють оперативно виконувати замовлення різної складності.</p>
                        </div>
                        <div className="advantages-col">
                            <h3>&#10004;&#160;Адекватні ціни</h3>
                            <p>Співвідношення ціна-якість наших виробів та послуг вас приємно здивує.</p>
                        </div>
                        <div className="advantages-col">
                            <h3>&#10004;&#160;Гарантійний термін</h3>
                            <p>На свої роботи ми надаємо гарантію, тому що впевнені в якості наших виробів і виконаних робіт.</p>
                        </div>
                    </div>
                </div>
                <div id="about"></div>
                <div className="main-about">
                    <h2>Компанія «Bilta»</h2>
                    <div className="div-line"></div>
                </div>
                <div className="about-text">
                    <div className="about-text-col"><p>«Bilta» — це новий сучасний завод, що спеціалізується на виробництві якісних металоконструкцій різної складності, металевих резурвуарів різного об’єму, каркасів для буронабивних паль та світлопрозорих конструкцій на основі технологій Rehau. </p></div>
                    <div className="about-text-col"><p>Ми успішно співпрацюємо з організаціями, що спеціалізуються на будівництві агропромислових комплексів і транспортної інфраструктури, промислових об'єктів і цивільного будівництва, виробляємо об'ємні резервуари та багато іншого. </p></div>
                    <div className="about-text-col"><p>«Bilta» може допомогти з проектуванням, монтажем і організацією будівництва об'єктів різної складності. Ми зацікавлені в довгостроковому і плідному співробітництві, тому на перше місце ставимо якість, при цьому чітко стежимо за термінами виконання замовлення і не завищуємо ціни. </p></div>
                </div>
                <div className="about-img">
                </div>

                <div id="mk"></div>
                <div className="mk-block">
                    <h2>Виробництво металоконструкцій</h2>
                    <div className="mk-block-text">

                        <p>Виробництво металоконструкцій трудомісткий процес, що вимагає злагодженої роботи багатьох фахівців. Щоб домогтися кращої якості та ціни, ми розбиваємо виробництво металоконструкцій на етапи. </p>


                        <p><strong>Проектування</strong> — це перший і найважливіший етап у виробництві металоконструкцій. Частина замовлень до нас надходить вже опрацьованими інженерами-проектувальниками замовника, інші замовлення опрацьовують наші фахівці, або ми залучаємо інженерів-проектувальників з провідних проектних бюро. </p>

                        <img className="img-left" src={imgmk1} alt="mk1" />

                        <p>Проектування й опрацювання креслень КМД необхідні для оптимізації витрат і виробничого процесу, адже в процесі опрацювання часто зменшується вага конструкцій, або поліпшуються їх характеристики при збереженні ваги. </p>

                        <p><strong>Підготовка до виробництва</strong> — наступний етап у виробництві металоконструкцій. Перед безпосереднім виготовленням металоконструкцій наші фахівці ретельно контролюють якість сировини, що надходить, налаштовують обладнання та готують виробничі лінії. Ретельна підготовка дозволяє уникати простоїв і скоротити терміни виробництва, а також мінімізувати брак і зменшити непотрібні витрати. </p>


                        <p><strong>Виробництво металоконструкцій</strong> — основний етап нашої роботи, що виконується на сучасному обладнанні одного з провідних турецьких виробників. Виробнича лінія змонтована в 2020 році, відповідає найвищим стандартам галузі та дозволяє виробляти більше 500 т металоконструкцій щомісяця. </p>

                        <p>Технічні можливості обладнання дозволяють робити унікальні металоконструкції, які поки що можемо робити тільки ми. Виробнича лінія автоматизована та контролюється досвідченими інженерами, що забезпечує високу швидкість і відмінну якість вироблених конструкцій. Ми постійно оновлюємо технічну базу, вдосконалюємо процеси виробництва та підвищуємо кваліфікацію працівників щоб забезпечити компанії позицію лідера галузі.</p>


                        <p><strong>Антикорозійна обробка металоконструкцій</strong> — невід'ємний етап сучасного виробництва металоконструкцій, що використовуються на об’єктах різного призначення і мають бути надійними та довговічними. </p>

                        <p>Попередньо сировина проходить етап антикорозійної обробки та консервації ще на етапі приймання. Для цього використовується сучасна установка з дробометного очищення металу, після чого метал обробляється антикорозійними засобами провідних світових виробників таких як Hempel, Tikkurila, Tekmos. </p>

                        <img className="img-right" src={imgmk2} alt="mk2" />

                        <p>Готова продукція також обробляється антикорозійними засобами, що дозволяє значно продовжити термін використання готових конструкцій в різних умовах. </p>

                        <p><strong>Контроль якості виробленої продукції</strong> — невід’ємний  етап будь-якого сучасного виробництва. Ми вже згадували раніше про контроль сировини на етапі підготовки і попередньої обробки металу. Крім цього, наш ВТК контролює всі етапи виробництва з фіксацією фізичних показників вироблених конструкцій (геометрія заготовок, контроль якості зварних швів, якість очищення металу, якість покриття ґрунтовки і фарби). </p>

                        <p>ВТК ретельно контролює упаковку та завантаження готових конструкцій, що має відповідати розробленій заздалегідь схемі завантаження кожного елементу по черзі. На фінальному етапі ще раз контролюється цілісність лакофарбового покриття і якість фіксації завантажених елементів. </p>

                        <p><strong>Доставка металоконструкцій</strong> — несправедливо недооцінений етап, який несе в собі ризики збереження цілісності металоконструкцій і своєчасність виконання замовлення при залученні випадкових виконавців. У нас є парк відповідного транспорту, що дозволяє своєчасно і без непотрібного ризику доставити вироблені елементи конструкції на об'єкти замовників. </p>

                        <p><strong>Монтаж металоконструкцій</strong> — фінальний етап нашої роботи, що може бути виконаний і власними силами замовника. Але накопичені знання та досвід, кваліфікований персонал і спеціалізована техніка дозволяють нам зробити монтаж якісно, швидко і за адекватну вартість. Тому послуги монтажу практично завжди клієнти замовляють у нас. </p>
                    </div>

                </div>

                <div id="tb"></div>
                <div className="tb-block">
                    <h2>Виробництво резервуарів</h2>
                    <div className="tb-block-text">
                        <p>Металеві резервуари знаходять широке застосування як в бізнесі, так і в приватному секторі. Вони використовуються для зберігання рідин з різними характеристиками, та бувають різних розмірів і форм. </p>

                        <img className="img-left" src={reservoir} alt="reservoir" />


                        <p>Найчастіше металеві резервуари використовуються для зберігання води та нафтопродуктів, але можуть використовуватися для зберігання агресивних хімічних речовин, які можуть вступати в реакцію з металом (тому перед виробництвом/купівлею резервуара потрібно чітко розуміти для яких цілей він потрібний). </p>
                        <p>Металеві резервуари бувають наземними і підземними, високого та низького тиску, утеплені та без утеплення, зі звичайної та нержавіючої сталі, а також інших металів (наприклад, алюмінію). </p>
                        <p>«Білта» приділяє увагу розвитку в області металоконструкцій і постійно розширює виробничі лінії (додаємо нове обладнання), завдяки чому ми налагодили виробництво сталевих резервуарів різної ємності. </p>
                        <p>Як і з іншими видами металоконструкцій, завдяки контролю всіх етапів виробництва, новому обладнанню і навченому персоналу, ми якісно виготовляємо металеві резервуари під потреби замовника. </p>
                        <p>Ми використовуємо тільки якісну сталь, ретельно контролюємо якість швів, обробляємо антикорозійними засобами і забезпечуємо доставку до замовника. Наші резервуари можна використовувати для зберігання питної і технічної води (наприклад, для зрошення полів), встановлювати горизонтально, або вертикально, робити мобільними, або стаціонарними. </p>
                        <p>Якщо у вас є потреба в резервуарі, звертайтеся до нас і ми прорахуємо його вартість, звернемо вашу увагу на важливі нюанси та зробимо найбільш вигідну пропозицію з урахуванням високої якості, оперативності виконання замовлень та доставкою до замовника. </p>


                    </div>
                </div>

                <div id="bv"></div>
                <div className="bv-block">
                    <h2>Каркаси для буронабивних паль</h2>
                    <div className="bv-block-text">
                        <p>Завдяки своїй практичності і універсальності буронабивні палі стають дедалі популярнішими. Їх використовують не тільки в промисловому, але навіть в приватному будівництві, адже вони допомагають робити надійні фундаменти на складних ґрунтах, а також в умовах щільної забудови і близькості ґрунтових вод. </p>
                        <img className="img-left" src={karkas} alt="karkas1" />

                        <p>Технологія буронабивних паль відома давно і вже добре відпрацьована, а її особливостями можна вважати армований каркас, який доповнює стандартні властивості бетону, збільшуючи діапазон експлуатації будівель.</p>
                        <p>Основна і незаперечна перевага буронабивних паль — це швидкість встановлення (при високому рівні надійності) і компактність (технологія не вимагає великого простору і її успішно використовують в містах). </p>
                        <p>Традиційно бетон бере на себе функції стиснення (тиску на фундамент), а ось армований каркас бере на себе функції розтягування, що відмінно працює на складних ґрунтах, де можливе часткове (або повне) просідання фундаменту. </p>
                        <p>Основою армованих каркасів для буронабивних паль є метал, характеристики якого і впливають на міцність і витривалість паль (звичайно ж в зв'язці з бетоном). І ось тут не потрібно економити, адже фундамент— це несуча конструкція, від якої залежить цілісність всієї будівлі. </p>
                        <p>Зазвичай товщину і щільність несучих стрижнів каркаса для буронабивних паль прораховують інженери ще на стадії проектування будівель, коли враховуються характеристики ґрунту, граничні навантаження і діапазон використання зведених споруд. </p>
                        <p>У виробництві каркасів для буронабивних паль дуже важливо дотримуватися точності розмірів (каркас розміщується в стовбурі свердловини і повинен як мінімум відповідати діаметру свердловини), рівновіддаленість стрижнів (щоб правильно розподіляти навантаження на палю), якість плетіння, висока якість металу і відповідна розрахунковим навантаженням товщина прутів (стрижнів). </p>
                        <p>Виробництво каркасів для буронабивних паль в кустарних умовах як мінімум грішить точністю розмірів і якістю плетіння, не кажучи вже про якість металу. «Білта» використовує якісний метал і сучасне обладнання, яке дозволяє швидко і якісно виробляти каркаси для буронабивних паль. </p>
                        <p>За необхідністю наші інженери можуть провести всі необхідні розрахунки (якщо у вас немає готової документації), що дозволяє спроектувати і виробити каркаси для буронабивних паль відповідно до майбутніх навантажень і без зайвих витрат. </p>
                        <p>У нас метал проходить контроль якості не тільки на стадії виробництва, але ще на стадії прийому та зберігання. А готові каркаси для буронабивних паль контролює наш відділ якості, що мінімізує випадки виробничого браку. </p>
                        <p>Ми готові виробляти каркаси для буронабивних паль під ваші запити швидко і якісно, при цьому чітко налагоджені процеси, спеціальні ціни у постачальників металу і відсутність посередників дозволяють істотно знизити собівартість продукції. </p>
                        <p>Звертайтеся за прорахунками, надавайте готові креслення (якщо вони у вас є), і ми прорахуємо вартість готової продукції, яка вас обрадує. Ми зацікавлені в тривалій і плідній роботі, тому відповідаємо за якість і не завищуємо ціни. </p>


                    </div>
                </div>

                <div id="reh"></div>
                <div className="reh-block">
                    <h2>Світопрозорі конструкції</h2>
                    <div className="reh-block-text">
                        <p>Світлопрозорі конструкції — це вироби з використанням технології Rehau, які мають нестандартні розміри і характеристики. До світлопрозорих конструкцій відносяться вікна, двері, стіни, фасади, дахи та інші конструкції, де важливо домагатися проникнення денного світла. ТОВ «Ерідон Буд» досвідчений виробник світопрозорих конструкцій та авторизований партнер Rehau.</p>

                        <img className="img-left" src={imgreh} alt="rehau" />

                        <p>Технологія Rehau дозволяє робити світлопрозорі конструкції з відмінними теплоізоляційними характеристиками, домагаючись збереження комфортного клімату всередині приміщення з ефективними витратами енергії на обігрів, або охолодження. При цьому використання світлопрозорих конструкцій дозволяє збільшити продуктивність в тваринництві, поліпшити мікроклімат в приміщенні і знизити витрати на освітлення. </p>

                        <p>Наявність підготовлених виробничих приміщень, сучасного обладнання і складу матеріалів дозволяє нам оперативно виробляти конструкції різної форми і складності. Технології Rehau впевнено конкурують з численними виробниками, доводячи свою надійність і довговічність. </p>

                        <p>Найбільш популярними видами світлопрозорих конструкцій є: </p>

                        <p><strong>Металопластикові вікна</strong>. Вікна можна виробляти різних розмірів і форм, підлаштовуючись під фактичні розміри отворів. Зі зростанням вартості енергоносіїв все частіше вікна замовляють для виробничих приміщень, опалювати які стає все дорожче зі старими вікнами. </p>

                        <p><strong>Металопластикові двері</strong>. Використовуються як в побутових, так і у виробничих приміщеннях. Як і вікна, допомагають зберегти тепло і заощадити на опаленні. Крім цього вироби з профілю Rehau стійкі до стирання і довго служать навіть у високонавантажених приміщеннях. </p>

                        <p><strong>Металопластикові стіни</strong>. Використовуються як для поділу приміщень, так і для створення додаткових закритих приміщень/кімнат. Часто зустрічаються на прохідних, як передбанник, або в приватному будівництві (для будівництва альтанок, літніх кухонь тощо). </p>

                        <p>Універсальність металопластикового профілю дозволяє успішно застосовувати його як в цивільному, так і в промисловому будівництві, а використання світлопрозорих конструкцій при реконструкції будівель значно прискорює процес реконструкції і підвищує енергоефективність будівель і споруд. </p>

                        <p>З питань розрахунку та консультацій звертайтесь до нашого менеджера: Богдан, <a href="tel:+380503370616">050-337-06-16</a>. </p>

                    </div>
                </div>

                <div id="contacts"></div>
                <div className="main-contacts">
                    <h2>Контакти</h2>
                    <div className="div-line"></div>
                </div>
                <div className="contacts-block">
                    <div className="contacts-block-col">
                        <h3>Адреса:</h3>
                        <p>09114, Україна, м. Біла Церква, вул. Івана Кожедуба 359</p>
                        <h3>Телефони і пошта:</h3>
                        <ul>
                            <li>&#9990; <a href="tel:+380952833557">+38 044 536 99 11</a></li>
                            <li>&#9990; <a href="tel:+380445369911">+38 044 536 99 11</a></li>
                            <li>&#9993; <a href="mailto:info@bilta.com.ua">info@bilta.com.ua</a></li>
                        </ul>
                        <h3>Години роботи:</h3>
                        <p><strong> Понеділок-П'ятниця</strong>: 8:30-17:30</p>
                        <p><strong> Субота-Неділя</strong>: Вихідні</p>
                    </div>
                    <div className="contacts-block-col">
                        <h3>Карта:</h3>
                        <iframe src="https://www.google.com/maps/d/embed?mid=1vRxgAp6rkGdGfDsf5NxyUpr2aNf1n_Vr" width="100%" height="80%" title="Map"></iframe>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Main;
