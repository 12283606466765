import './App.css';
import Header from './Header';
import Header2 from './Header2';
import Main from './Main';
import Footer from './Footer.js';

const nav = [
  { "link": "#services", "text": "Послуги" },
  { "link": "#about", "text": "Про нас" },
  { "link": "#contacts", "text": "Контакти" }
];

const navServices = [
  { "link": "#mk", "text": "Металоконструкції" },
  { "link": "#tb", "text": "Резервуари" },
  { "link": "#bv", "text": "Каркаси для паль" },
  { "link": "#reh", "text": "СПК" }
];

function App() {
  return (
    <div>
      <Header nav={nav} />
      <Header2 navServices={navServices}></Header2>
      <Main />
      <Footer />
    </div>
  );
}

export default App;
